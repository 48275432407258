.infront{
    z-index:40;
    position:relative
}


.behind{
    z-index:0;
    position:relative

}



@media print {
    .no-print {
      visibility: hidden;
    }
  }