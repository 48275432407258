body{
  font-family: "Montserrat";
}

.avatar {
    vertical-align: middle;
    width:70px;
    height:70px;
    border-radius: 50%;
    margin:-40px auto auto auto;
    box-shadow:0 4px 8px 0 rgba(0,0,0,0.2);
  }

  .userAvatar {
      justify-content: start;
    width:70px;
    height:70px;
    border-radius: 50%;
    box-shadow:0 4px 8px 0 rgba(0,0,0,0.2);
  }

.disclaimer {
 width:50%;
 margin-top:10%;
}


@media screen and (max-width: 480px) {
    .disclaimer {
        width:100%;
        margin-top:40%;
       }
  }
  

  @media screen and (min-width: 1200px) {
    .disclaimer {
        width:50%;
        margin-top:10%;
       }
  }