.smallIcon{
    height:14px;
    width:14px
}

.pricingButton:hover{
    background:#ff2000
}


@media screen and (min-width: 900px)  {
    .cardFlex{
        display:flex
    }

    .pricingText{
        margin:auto;
        width:80%
    }

    .centerLg{
        margin:0px auto 0px auto;
        width:40%;
    }
    .centering{
        width:40%;
        margin:auto
    }

    .pricingContainer{
        margin:90px auto auto auto;
        width:70%
    }
    .features li {
        margin:17px 0px 0px 0px
    }
    
    .buttonContainer{
        margin:20px 0px 20px 0px
    }
    
    .pricingButton{
        background:#eb3f3f;
        padding: 10px 25px 10px 25px !important;
        color:white ! important;
        border-radius:10px
    }
    
    
}



@media screen and (max-width: 900px){
.pricingContainer{
    margin:90px auto auto auto;
    width:70%
}
.features li {
    margin:10px 0px 0px 0px
}
.centering{
    margin:auto
}

.buttonContainer{
    margin:20px 0px 20px 0px
}

.pricingButton{
    background:#eb3f3f;
    padding: 10px 25px 10px 25px !important;
    color:white ! important;
    border-radius:10px
}

.pricingText{
    margin:auto;
    width:90%
}


}