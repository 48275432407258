#primary_color{
    border-radius: 50%;
    height: 30px;
    width: 30px;
    border: none;
    outline: none;
    -webkit-appearance: none;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

#primary_color::-webkit-color-swatch-wrapper {
    padding: 0; 
}
#primary_color::-webkit-color-swatch {
    border: none;
    border-radius: 50%;
}